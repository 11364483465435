









































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class MemberResetPassword extends Vue {
  @Prop() public id!: number;
  @Prop() public token!: string;

  public $store: any;
  public $q: any;
  public $route: any;

  public loading: boolean = false;
  public info: any = {
    username: '',
    lastname: '',
    email: '',
  };
  public error: string = '';
  public password: string = '';
  public confirmPassword: string = '';

  private rules = [
    (val: string) => /[A-Z]/.test(val) || 'Le mot de passe doit contenir au moins une majuscule',
    (val: string) => /[a-z]/.test(val) || 'Le mot de passe doit contenir au moins une minuscule',
    (val: string) => /[0-9]/.test(val) || 'Le mot de passe doit contenir au moins un chiffre',
    (val: string) => val.length >= 14 || 'Le mot de passe doit faire au moins 14 caractères',
  ];

  private handleSubmit() {
    const token: string = this.token;
    const password: string = this.password;
    const userId: any = this.id;
    this.loading = true;
    this.$store.dispatch('resetPassword', {
      payload: {
        token, password, userId,
      },
    }).then((response: any) => {
      this.info = response;
      this.loading = false;
      this.$store.dispatch('logout');
      this.$router.push(`/login?msg=PASSWORD_UPDATED`);
    }).catch((error: any) => {
      this.loading = false;
      this.error = error.message;
    });
  }

}
